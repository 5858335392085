.dashboard-columns {
  display: block;
  .right-column {
    .cardBox {
      height: 384px;
    }
  }
}

@media screen and (min-width: 700px) {
  .dashboard-columns {
    display: flex;
    flex-wrap: wrap;
    .left-column {
      width: 100%;
      .left-column__bottom {
        display: flex;
        justify-content: space-between;
        .services-div {
          flex-grow: 1;
          max-width: 48%;
        }
      }
    }
    .right-column {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .right-column-div1,
      .right-column-div2 {
        flex-grow: 1;
        max-width: 48%;
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .dashboard-columns {
    flex-wrap: nowrap;
    .right-column {
      display: block;
      width: fit-content;
      margin-left: 30px;
      .right-column-div1,
      .right-column-div2 {
        max-width: unset;
      }
    }
  }
}
